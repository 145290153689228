import React from 'react';
import { graphql } from 'gatsby';

import SEO from '../../components/seo';
import GiveContentTrans from '../../components/GiveContentTrans';
import Layout from '../../components/Layout';

function AboutPage() {
  return (
    <Layout>
    <main className="open-service-page outer-grid">
      <GiveContentTrans language="et">
        <SEO
          title="Wordpress arendus"
          description="Pakume Wordpressi baasil kodulehtede täislahendusi unikaalse disainiga ning 
          tehnilisi erilahendusi pluginate näol."
          image="/pagecover-teenused.jpg"
        />
      </GiveContentTrans>
      <GiveContentTrans language="en">
        <SEO
          title="Wordpress development"
          description="We offer full website solutions based on Wordpress with a unique design and special technical solutions. "
          image="/pagecover-en-default.jpg"
        />
      </GiveContentTrans>

      <section className="hero">
        <GiveContentTrans language="et">
          <h1>Wordpress arendus</h1>
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <h1>Wordpress development</h1>
        </GiveContentTrans>

        <div className="page-copy">
          <GiveContentTrans language="et">
            <p>
              Wordpress on kahtlemata üks tuntumaid sisuhaldustarkvarasid 
              millele kodulehti ja ka keerukamaid erilahendusi luuakse. 
              Meie pakume Wordpressi baasil kodulehtede täislahendusi unikaalse disainiga ehk teemaga ning 
              tehnilisi erilahendusi pluginate näol. 
            </p>
          </GiveContentTrans>
          <GiveContentTrans language="en">
            <p>
              WordPress is undoubtedly one of the most well-known content management systems used 
              for creating both basic and complex websites. 
              We offer full website solutions based on Wordpress with a unique design theme and special technical solutions in the form of plugins. 
            </p>
          </GiveContentTrans>
        </div>
      </section>

      <section className="description-section inner-grid">

        <div className="content-row">
          <div className="content-col-half page-copy">
            <GiveContentTrans language="et">
              <h3>Wordpressi erilahendused</h3>
              <p>
                Tänapäeval pole koduleht tihti ainult koduleht, vaid ka tööriist oma äriprotsesside juhtimiseks ja automatiseerimiseks. 
              </p>
              <p>
                Väga paljud vajadused on erinevate pluginate abil lahendatavad nagu näiteks tehniline SEO, kontaktvormid, broneerimissüsteemid, 
                päringu vormid, online kataloogid, lihtsad e-poed ja makselahendused. 
              </p>
              <p>
                Aga vahel võib tekkida olukordi kus valmislahendusest ei piisa. 
                Tänu lihtsale laiendamise võimalustele Wordpress platvormil saame 
                vajadusel luua erilahendused just teie vajadusi arvestades. 
              </p>
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <h3>Wordpress custom solutions</h3>
              <p>
                Today, a website is often not just a website, but also a tool for managing and automating your business processes.
              </p>
              <p>
                Many business needs can be solved with the help of various plugins, such as technical SEO, 
                contact forms, booking systems, inquiry forms, catalogs, simple e-shops and payment solutions.
              </p>
              <p>
                But sometimes there may be situations where a ready-made solution is not enough. 
                Thanks to the easy expansion options on the Wordpress platform, we can create special 
                solutions, if necessary, taking into account your needs.
              </p>
            </GiveContentTrans>
          </div>
          <div className="content-col-half page-copy">
            <GiveContentTrans language="et">
              <h3>Täislahendused unikaalse disainiga</h3>
              <p>
                Meie kindel eelis on silmapaistev ja läbimõeldud disain. Vajadusel realiseerime selle Wordpressi 
                baasil täislahendusena. Loome teema, konfigureerime pluginad, aitame esmase 
                sisu sisestamisega, heade nõuannetega SEO osas ning loomulikult lehe serverisse üles panemisega. 
              </p>
            </GiveContentTrans>
            <GiveContentTrans language="en">
              <h3>Complete solutions with a unique design</h3>
              <p>
                Our definite advantage is an outstanding and well-thought-out design. If necessary, 
                we implement it as a complete solution based on Wordpress. 
                We create a theme, configure plugins, help with initial content 
                input, good SEO advice and, of course, uploading the page to the server.
              </p>
            </GiveContentTrans>
          </div>
        </div>

      </section>

      <section className="description-section inner-grid">
        <div className="content-col page-copy">
          <GiveContentTrans language="et">
            <h2>Pikaajaline kogemus</h2>
            <p>
              Meie kogemused Wordpressiga kodulehtede tegemisel on sellest ajast kui see populaarseks sai. 
              Nagu paljud Wordpress agentuurid, oleme ka meie sellega koos üles kasvanud, näinud arengut ning ka pidevalt praktiseerinud.
            </p>
          </GiveContentTrans>
          <GiveContentTrans language="en">
            <h2>Long-term experience</h2>
            <p>
              Our experience in building websites with WordPress dates back to when it first became popular. 
              Like many WordPress agencies, we too have grown with it, witnessed its evolution and consistently practised with it.
            </p>
          </GiveContentTrans>
        </div>
      </section>


      <section className="description-section inner-grid">
        <div className="content-col page-copy">
          <GiveContentTrans language="et">
            <h2>Kuidas saame aidata?</h2>
            <p>
              Olgu see spetsiifilise API-ga ühendamine, andmebaasi loomine, 
              mõne protsessi digitaliseerimine või täiesti uue Wordpress lehe disainimine ja loomine. Teeme ära. 
            </p>
            <p>
              Võtke meiega ühendust ja leiame teile sobiva lahenduse. 
            </p>
          </GiveContentTrans>
          <GiveContentTrans language="en">
            <h2>How can we help you? </h2>
            <p>
              Be it connecting to a specific API, creating a database, digitizing some processes or designing and creating a completely new Wordpress page. We'll do it. 
            </p>
            <p>
              Contact us and we will find a solution.
            </p>
          </GiveContentTrans>
        </div>
      </section>
    </main>
    </Layout>
  );
}

export default AboutPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;